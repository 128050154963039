iframe {
	transition: all .01s ease-in-out;
}

.preview-overlay {
	display: none;
	background-color: $light-yellow;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 2000;

	&--iframe {
		position: absolute;
		z-index: 3000;
		top: 60px;
		left: 0;
		margin-left: auto;
		right: 0px;
		margin-right: auto;
		background: $plain-white;
	}

	&--frame-mobile {
		box-sizing: content-box;
		border: solid 11px $very-dark-grey;
		border-top-width: 40px;
    	border-bottom-width: 40px;
    	-webkit-border-radius: 8px;
    	-moz-border-radius: 8px;
    	border-radius: 8px;
	}

	&--frame-tablet {
		box-sizing: content-box;
		border: solid 18px $very-dark-grey;
		border-top-width: 60px;
    	border-bottom-width: 60px;
    	-webkit-border-radius: 8px;
    	-moz-border-radius: 8px;
    	border-radius: 8px;
	}

	&--frame-desktop {
		border: none;
	}

	&--tablet-box {
		transform: scale(.6);
		margin-top: -30px;
	}

	&--desktop-box {
		transform: scale(1);
		margin-top: -60px;
	}

	&--icon-box {
		// @extend %overlayContentBox;
		position: absolute;
		position: absolute;
	    right: 1em;
	    top: 7em;
	    width: 7em;
	    height: 23em;
	}
}