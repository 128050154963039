// menu and heading underline
%Underline {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	width: 0;
	height: 2px;
	background: linear-gradient(90deg, #42b574 0%, #84c450 100%);
	transition: .3s all ease;
}

// text slide down on project pop up
%animationSlideDown {
	animation: slideDown .75s linear backwards;
	-webkit-animation: slideDown .75s linear backwards;
}

%animationSlideDownDisappear {
	animation: slideDownDisappear .75s linear forwards;
	-webkit-animation: slideDownDisappear .75s linear forwards;
}


// Previous - Next Swipe Buttons on project pop up

%swipeBefore {
	width: 0;
    height: 0;
    border-style: solid;	
}

%swipePrevNext {
	position: absolute;
	opacity: 0;
	transition: 0.3s;
    top: -20%;
    width: 100px;
    height: 56.25px;
    background-size: contain;
    border: 2px solid $light-grey;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0px -1px 0 rgba(255, 255, 255, 0.5);	
}

%swipePrevNextAfter {
	position: absolute;
    top: 100%;
    left: 0;
    margin: 20px 0 0;
    width: 100%;
    color: $medium-red;
    font-family: $stack-open-sans;
    // text-shadow: .3px .3px $plain-white;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    font-size: 13px;
    opacity: 0;
    transition: 0.3s;
}

%swipePrevNextHoverAfter {
	opacity: 1;
	transition-delay: 0.2s;
	-webkit-transform: translateX(0%);
	transform: translateX(0%);
}

%subtitilesOnPopUp {
    color: $dark-red;
    background: rgba(255,255,255,.8);
    padding: 0.25em 0.5em;
    // text-shadow: .5px .5px $lighter-red;
}

// Border and background of content box on the overlay
%overlayContentBox {
    border: 10px solid #f7f7f7;
    background: rgba(255,255,255,0.7);
}

// Social and contact icons transitions 
%iconsTransition {
    -webkit-transition: all 200ms cubic-bezier(0.785,0.135,0.15,0.86),background-color 200ms cubic-bezier(0.785,0.135,0.15,0.86);
    -moz-transition: all 200ms cubic-bezier(0.785,0.135,0.15,0.86),background-color 200ms cubic-bezier(0.785,0.135,0.15,0.86);
    -o-transition: all 200ms cubic-bezier(0.785,0.135,0.15,0.86),background-color 200ms cubic-bezier(0.785,0.135,0.15,0.86);
    transition: all 200ms cubic-bezier(0.785,0.135,0.15,0.86),background-color 200ms cubic-bezier(0.785,0.135,0.15,0.86);
}