// General styling for button
.button {
	width: 25%;
	margin: 0 auto;
	padding: 10px 0;
	color: $lime;
	background: transparent;
	border: 2px solid $lime;
	transition: .3s all ease;
	
	&:hover {
		color: $light-yellow;
		background: $lime;
		cursor: pointer;
	}
	
	&-lime {
		color: $lime;
		border: 2px solid $lime;
		&:hover {
			color: $light-yellow;
			background: $lime;
		}
	}

	&-grey {
		color: $very-dark-grey;
		border: 2px solid $light-yellow;
		background: $light-yellow;

		&:hover {
			color: $light-yellow;
			background: $dark-grey;
			border: 2px solid $dark-grey;
		}
	}

	&-yellow {
		width: 16em;
		color: $very-dark-grey;
		border: 2px solid $light-grey;
		background: $intensive-yellow;
		padding: 4.5px 0;

		@include screen(smallmobileLandscape) {
			width: 40%;
		}
		@include screen(mobileLandscape) {
			width: 40%;
		}
		a {
			color: $plain-white;
			text-shadow: .5px .5px .5px $very-dark-grey;
		}

		&:hover {
			color: $light-yellow;
			background: $dark-grey;
			border: 2px solid $light-yellow;
			a {
				color: $light-yellow;
			}
		}
	}

}

// Left and right arrow buttons to swipe b/w projects in project overlay
.swipe-button{
	width: 60px;
    height: 100px;
    cursor: pointer;
    position: fixed;
    top: 37.5vh;
    z-index: 100;
    
    &::before {
    	content: '';
    	position: absolute;
    	top: 50%;
    	-webkit-transform: translateY(-50%);
  		transform: translateY(-50%);
    }
    
    &::after {
    	position: absolute;
    	top: calc(50% - 1px);
    	width: 15px;
    	height: 2px;
    	background: linear-gradient(90deg, $medium-red 0%, #f48888 100%);
    	content: '';
    	-webkit-transition: width 0.3s 0.2s;
    	transition: width 0.3s 0.2s;
    	-webkit-backface-visibility: hidden;
    	backface-visibility: hidden;
    }

	&:hover::after{
		width: 127px;
	    transition-delay: 0s;
	}

	&--previous {
		left: .5em;

		&::before {
			@extend %swipeBefore;
    		border-width: 8px 13px 8px 0;
    		border-color: transparent $medium-red transparent transparent;
    		left: 0;
		}

		&::after {
			left: 13px;
		}
	}

	&--next {
		right: .5em;

		&::before {
			@extend %swipeBefore;
		    border-width: 8px 0 8px 13px;
		    border-color: transparent transparent transparent $medium-red;
		    right: 0;
		}

		&::after {
			right: 13px;
			background: linear-gradient(90deg, #f48888 0%, $medium-red 100%);
		}
	}

	&__preview-prev {
		@extend %swipePrevNext;
    	left: 30px;
    	background-image: url("img/projects/gallery1.jpg");
    	
    	&::after {
    		content: 'Previous';
    		@extend %swipePrevNextAfter;
		    transform: translateX(125%);
    	}
	}
	
	&__preview-next {
		@extend %swipePrevNext;
    	right: 30px;
    	background-image: url("img/projects/video1.jpg");
    	&::after {
    		content: 'Next';
    		@extend %swipePrevNextAfter;
		    transform: translateX(-125%);
    	}
	}

	&:hover .swipe-button__preview-prev {
		opacity: 1;
	}
	&:hover .swipe-button__preview-prev::after {
		@extend %swipePrevNextHoverAfter;
	}
	&:hover .swipe-button__preview-next {
		opacity: 1;
	}
	&:hover .swipe-button__preview-next::after {
		@extend %swipePrevNextHoverAfter;
	}
}

// Back to top Button
.back-to-top {
	opacity: 0;
	position: fixed;
	right: 15px;
	bottom: 80px;
	@include screen(tablet) {
		right: 65px;
		bottom: 80px;
	}
	transition: all .5s linear;
	cursor: pointer;
	svg {
		width: 40px;
		height: 40px;
	}
}


// Buttons for live preview of a project
.preview-mobile,
.preview-tablet,
.preview-desktop,
.preview-overlay-close {
	display: inline-block;
	position: relative;
	width: 55px;
	height: 55px;
	background: $plain-white;
	padding: 0.9em;
	border: 3px solid $dark-grey;
	border-radius: 50%;
	margin: .5em;
	cursor: pointer;
	transition: all .3s ease-in;

	&:hover {
		background: #dedede;
		border: 3px solid #dedede;
	}

	&:hover svg {
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
	}

	svg {
		width: 30px;
		height: 30px;
		position:absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		margin: auto;
		transition: all .3s ease-in;
		transform-origin: 15px 15px;
	}
}

// Button to close project overlay
.preview-overlay-close svg {
	width: 25px;
	height: 25px;
}

// Button for more description text on the project overlay
.more-button {
	position: absolute;
	width: 15%;
	height: 25px;
	right: 1.2em;
	bottom: -1em;
	@include screen(smallmobileLandscape){
		right: 1em;
		height: 22.5px;
		font-size: .75em;
	}

	@include screen(mobileLandscape) {
		right: 1em;
		height: 22.5px;
		font-size: .75em;
	}
	@include screen(tablet) {
		width: 10%;
		height: 25px;
		right: 1.6em;
		bottom: -1.5em;
	}
	margin: 0;
	font-weight: 400;
	color: $plain-white;;
    padding: 2px 0 0 1px;
    font-size: 0.85em;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: -webkit-gradient(linear, left top, left bottom,
    from(#838383), color-stop(.49, #444444), color-stop(.49,#303030),
    to(#333333));
    border: solid 1px rgba(79, 79, 79, 0.75);
	font-size: .85em;

	&:hover {
		color: $very-dark-grey;
		background: $plain-white;
	}
}



