.footer {
	height: 105px;
	background: $very-dark-grey;
	color: white;
	text-align: center;

	@include screen(tablet) {
		height: 75px;
	}

	&__rights-claim {
		display: inline-block;
		margin-top: 25px;

		@include screen(tablet) {
			float: left;
			text-align: left;
			margin-top: 32px;
		}
	}

	&__rights-claim > p {
		margin: 0;
	}

	&__social-icons-container {
		display: block;
		text-align: center;
		@include screen(tablet) {
			float: right;
			display: inline-block;
		}
	}

}