// Color variables
$plain-white			: #fff;
$lime					: #42B574;
$light-yellow			: #fffef2;
$plain-black			: #000;
$dark-layer				: rgba(25, 25, 25, 0.6);
$intensive-yellow		: #ffd454;
$lighter-yellow			: #ffdc76;
$medium-red				: #EF5252;
$dark-red				: #ad2131;
$lighter-red			: #b53745;
$light-grey				: #f7f7f7;
$medium-grey			: #939393;
$dark-grey				: #1b1d1f;
$very-dark-grey			: #141618;



// Font stacks
$stack-lato				:	'Lato', Helvetica, Arial, sans-serif;
$stack-open-sans		: 	'Open Sans', Helvetica, Arial, san-serif;
$stack-poppins			:	'Poppins', Helvetica, Arial, sans-serif;


// Media queries break points
$break-very-small		:	370px;
$break-landscape-small	:	650px;
$break-small			:	766px;
$break-wide				:	1100px;

$boxWdith				:	100%;
$leftColumnWidth		: 	85px;
$dashboardHeadingWidth	: 	170px;
$socialMarginSumTablet	: 	4.3em;
$socialMarginSumDesktop : 	6.4em;

