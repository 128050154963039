body {
	font-family: $stack-open-sans;
	font-size: 1em;
	font-weight: 400;
}

.header-container {
	font-family: $stack-lato;
	font-size: 1.1em;
	font-weight: 700;
}

.menu {
	font-weight: 400;
	@include screen(tablet) {
		font-weight: 700;
	}
}

.key-messages {
	font-family: $stack-poppins;
	&-title {
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: -0.025em;
		
		font-size: 1.25em;

		@include screen(mobile) {
			font-size: 1.5em;
		}

		@include screen(tablet) {
			font-size: 2.5em;
		}
		
		@include screen(desktop) {
			font-size: 4.75em;
		}
	}
	&-content-text {
		font-size: 1em;
		line-height: 1.5;
		
		@include screen(tablet) {
			font-size: 1.8em;
		}
	}

	&-content-rotating-words {
		font-size: 1.3em;
		font-weight: 700;
		
		@include screen(tablet) {
			font-size: 2em;
		}
		@include screen(desktop) {
			font-size: 2.5em;
			font-weight: 700;
		}
	}

	&-subtitle {
		font-size: 1.2em;
		font-weight: 400;
		@include screen(tablet) {
			font-size: 2.4em;
		}
	}

}

.section {
	&--main-heading {
		font-family: $stack-lato;
		text-align: center;
		text-transform: uppercase;
		font-weight: 300;
		font-size: 1.5em;
		@include screen(tablet){
			font-size: 2em;
		}
	}

	&--subheading {
		font-family: $stack-poppins;
		font-weight: 300;
		text-transform: capitalize;
		font-size: 1.05em;		
		@include screen(tablet){
			font-size: 1.35em;
		}
	}
}

.feature {
	&--heading-typo {
		font-weight: 700;
		font-size: 1.25em;
		@include screen(tablet){
			font-size: 1.5em;
		}
	}
	&--description-typo {
		line-height: 1.3;
		font-size: 1em;
		@include screen(tablet){
			font-size: 1.1em;
			line-height: 1.4;
		}
	}

}

.projects {
	&__picture-title {
		font-size: 1.1em;
		font-weight: 300;
		@include screen(tablet){
		font-size: 1.3em;
		}
	}

}

.skills {
	&__name {
		font-family: $stack-lato;
		font-weight: 300;
		font-size: 1em;
	}
	&__name-project-page {
		font-family: $stack-lato;
		font-weight: 300;
		font-size: .5em;
		@include screen(mobile) {
			font-size: .7em;
		}
	}
}

.overlay {
	&__info-heading {
		font-size: 1.5em;
		@include screen(smallmobile){
			font-size: 1em;
		}
		@include screen(smallmobileLandscape){
			font-size: 1em;
		}
		@include screen(mobile){
			font-size: 1.2em;
		}
		@include screen(tablet) {
			font-size: 2em;
		}
		font-family: $stack-poppins;
		font-weight: 400;
		text-transform: capitalize;
	}

	&__info-subheading {
		font-size: 1em;
		@include screen(tablet) {
			font-size: 1.5em;
		}
		font-family: $stack-poppins;
		font-weight: 300;
		text-transform: uppercase;
	}

	&__info-text {
		// font-family: $stack-poppins;
		font-size: 1em;
		@include screen(smallmobileLandscape) {
			font-size: .7em;
		}
		@include screen(mobileLandscape) {
			font-size: .75em;
		}
		@include screen(tablet) {
			font-size: 1.35em;
		}
		font-family: $stack-lato;
		line-height: 1.5;
		font-weight: 300;
	}

	&__live-preview {
		h1 {
			color: $medium-red;
			font-family: $stack-lato;
			font-size: 2em;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
	
	&__visit-site {
		a{
			color: $plain-white;
			font-family: $stack-lato;
			font-size: 1em;
			font-weight: 600;
			text-transform: uppercase;
			@include screen(tablet) {
				font-size: 2em;
			}
		}
	}
}

.footer {
	&__rights-claim > p {
		font-size: 12px;
		font-weight: 100;
	}
}