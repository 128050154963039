// mobile menu close icon

.menu-mobile-line-icon::before {
	content: '';
	position: absolute;
	top:0px;
	left:0;
	width: 20px;
	height: 2px;
	border-radius: 1px;
	background-color: #FFF;
	transform-origin: 0 0;
	transition: transform .3s ease-out;
}

.menu-icon-bars {
	position: absolute;
	top: 9px;
	left:0;
	width: 20px;
	height: 2px;
	border-radius: 1px;
	background-color: #FFF;
	transition: all .3s ease-out;
	transform-origin: 0 50%;
}

.menu-mobile-line-icon::after {
	content: '';
	position: absolute;
	bottom: 0;
	right:0;
	width: 20px;
	height: 2px;
	border-radius: 1px;
	background-color: #FFF;
	transform-origin: 0 100%;
	transition: transform .3s ease-out;
}

.menu-mobile-line-icon-close-x {
	&::before {
		transform: rotate(45deg) scaleX(1.25) translateY(1px);
	}

	.menu-icon-bars {
		opacity: 0;
		transform: scaleX(0);
	}

	&::after {
		transform: rotate(-45deg) scaleX(1.25) translateY(0);
	}
}

// Circle skills icons styling
.skills {
	&__outer-circle {
		display: inline-block;
		position: relative;
		width: 124px;
		height: 124px;
		border: 5px solid transparent;
		border-radius: 50%;
		background: $plain-white;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
		transition: all 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);	
		margin: .25em .25em;
		@include screen(mobile) {
			margin: .5em;
		}
		@include screen(tablet) {
			margin: 1em;
		}
		&--project-page {
			width: 37.5px;
			height: 37.5px;
			margin: .25em .15em;
			@include screen(tablet) {
				width: 80px;
				height: 80px;
				margin: .25em .5em;
			}
		}
	}
	&__inner-circle {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: $plain-white;
		position: relative;
		top: 6%;
		left: 0;
		right: 0;
		margin: 0 auto;

		&--project-page {
			top: 9%;
			width: 25px;
			height: 25px;
			@include screen(tablet) {
				top: 9%;
				width: 50px;
				height: 50px;
			}
		}
	}
	
	&__icon {	
		position: absolute;
		top: 15%;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	
	&__name {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: 65%;
	}

	&__name-project-page {
		position: absolute;
		top: 137%;
		left: 0;
		right: 0;
		margin: 0 auto;
		color: $dark-grey;
		text-align: center;

		@include screen(tablet) {
			top: 95%;
		}
	}
}

.icon-html, .icon-css, .icon-js, .icon-jq, .icon-ajax, .icon-git, .icon-sass, .icon-postcss, .icon-gulp, .icon-node, .icon-npm, .icon-svg{
	background: url('img/icons/sprites.png') no-repeat;
	background-size: 300px 100px;
	width: 50px;
	height: 50px
}

.icon-html{
	background-position: 0 0;
}

.icon-css{
	background-position: -50px 0;
}

.icon-js{
	background-position: -97px 0;
}

.icon-jq{
	background-position: -146px 0;
}

.icon-ajax{
	background-position: -196px 0;
}

.icon-git{
	background-position: -247px 0;
}

.icon-sass{
	background-position: 1px -50px ;
}

.icon-postcss{
	background-position: -50px -50px ;
}

.icon-gulp{
	background-position: -99px -50px ;
}

.icon-node{
	background-position: -148px -50px ;
}

.icon-npm{
	background-position: -199px -50px ;
}

.icon-svg{
	background-position: -251px -50px ;
}

//Smaller size
.icon-html-sm, .icon-css-sm, .icon-js-sm, .icon-jq-sm, .icon-ajax-sm, .icon-git-sm, .icon-sass-sm, .icon-postcss-sm, .icon-gulp-sm, .icon-node-sm, .icon-npm-sm, .icon-svg-sm{
	background: url('img/icons/sprites.png') no-repeat;
	background-size: 120px 40px;
	width: 20px;
	height: 20px;

	@include screen(tablet) {
		background-size: 210px 70px;
		width: 35px;
		height: 35px;
	}
}

.icon-html-sm{
	background-position: 0 0;
}

.icon-css-sm{
	background-position: -20px 0;
	@include screen(tablet) {
		background-position: -35px 0;
	}
}

.icon-js-sm{
	background-position: -39px 0;
	@include screen(tablet) {
		background-position: -69px 0;
	}	
}

.icon-jq-sm{
	background-position: -58px 0;
	@include screen(tablet) {
		background-position: -102px 0;
	}
}

.icon-ajax-sm{
	background-position: -78px 0;
	@include screen(tablet) {
		background-position: -137px 0;
	}
}

.icon-git-sm{
	background-position: -99px 0;
	@include screen(tablet) {
		background-position: -174px 0;
	}
}

.icon-sass-sm{
	background-position: 1px -20px;
	@include screen(tablet) {
		background-position: 1px -35px;
	}
}

.icon-postcss-sm{
	background-position: -20px -20px;
	@include screen(tablet) {
		background-position: -35px -35px;
	}
}

.icon-gulp-sm{
	background-position: -39px -20px;
	@include screen(tablet) {
		background-position: -69px -35px;
	}
}

.icon-node-sm{
	background-position: -59px -20px;
	@include screen(tablet) {
		background-position: -104px -35px;
	}
}

.icon-npm-sm{
	background-position: -78px -20px;
	@include screen(tablet) {
		background-position: -138px -35px;
	}
}

.icon-svg-sm{
	background-position: -100px -20px;
	@include screen(tablet) {
		background-position: -175px -35px;
	}
}


// project close icon
.project--close {
	position: absolute;
	z-index: 1500;
	top: 1em;
	right: .5em;
	cursor: pointer;
	
	&-svg {
		width: 25px;
		height: 25px;
	}
}


// Contacts icons
.contacts {
	&--icon {
		display: block;
		position:absolute;
		left:0;
		margin: 0 auto;
		svg {
			width: 3em;
			height: 3em;
			fill: $medium-grey;
			@extend %iconsTransition;
		}
	}
}

.contacts--contact-row:hover {
	svg {
		fill: $plain-white;
	}
}

// Social icons
.footer__social-media-icon {
	display: inline-block;
	margin: 32px 1em 0;

	svg {
		width: 20px;
		height: 20px;

		@include screen(tablet) {
			width: 15px;
			height: 15px;
		}
		fill: $plain-white;
		@extend %iconsTransition;
	}

	&:hover {
		svg {
			fill: $lime;
		}
	}
}
