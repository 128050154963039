.menu {
	position: absolute;
	top: 125px;
	left: -1em;
	right: 20%;
	z-index: 500;
	transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
	transform: translateX(-700px);

	@include screen(smallmobile) {
		top: 107.5px;
	}

	@include screen(smallmobileLandscape) {
		right: 50%;
	}

	@include screen(mobileLandscape) {
		top: 105px;
		right: 50%;
	}

	@include screen(tablet) {
		position: relative;
		top: 0;
		display: inline-block;
		float: right;
		margin: 45px 0 0 0;
		transform: translateX(0);
	}

	&-mobile-line {
		position:absolute;
		top: 90px;
		left: 0%;
		width: 70px;
		height: 40px;
		color: $plain-white;
		background: $plain-black;
		margin: 0;

		@include screen(smallmobile) {
			top: 72.5px;
		}

		@include screen(smallmobileLandscape) {
			top: 70px;
		}
		@include screen(mobileLandscape) {
			top: 70px;
		}

		@include screen(tablet) {
			display:none;
		}

		&-icon {
			position: absolute;
			top:0; left:0; right:0; bottom: 0;
			z-index: 500;
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: auto;
		}
	}

	&-item {
		display: block;
		color: $plain-white;
		text-decoration: none;
		padding: 10px 0 10px 0;
		background: $plain-black;
		border-bottom: 1px solid $light-yellow;
		transition: all .1s ease-in-out;

		&:hover {
			color: $light-yellow;
			background: $dark-grey;
			@include screen(tablet){
				background: transparent;
			}
		}
		
		&:focus {
			background: $dark-grey;
			@include screen(tablet){
				background: transparent;
			}
		}


		@include screen(tablet){
			display: inline-block;
			position: relative;
			margin-left: 15px;
			background: transparent;
			padding: 10px 0 3.5px 0;
			border-bottom: none;
		}

		&:after {
			@include screen(tablet){
				content: '';
				position: absolute;
				top: 100%;
				left: 50%;
				width: 0;
				height: 2px;
				background: linear-gradient(90deg, #42b574 0%, #84c450 100%);
				transition: .3s all ease;
			}
		}
		
		&:hover:after {
			@include screen(tablet){
				left:0;
				width: 100%;
			}
		}
	}
}