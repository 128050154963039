.section {
	&--features,
	&--projects {
		text-align: center;
	}

	&--projects-extra-b-margin {
		@include screen(tablet) {
			margin-bottom: 1.5em;
		}
	}

	&--tb-padding {
		padding-top: 1em;
		padding-bottom: 1em;
		@include screen(tablet) {
			padding-top: 2em;
			padding-bottom: 2em;
		}
	}

	&--background-grey {
		background: $light-grey;
		background: -webkit-linear-gradient($plain-white, $light-grey); 
    	background: -o-linear-gradient($plain-white, $light-grey);
    	background: -moz-linear-gradient($plain-white, $light-grey);
		background: linear-gradient($plain-white, $light-grey);
	}

	&--background-white {
		background: $plain-white;
	}

	&--background-dark-grey {
		background: $dark-grey;
	}

	&--main-heading {
		display: inline-block;
		position: relative;
		text-transform: uppercase;
		
		&:after {
			@extend %Underline;
			left: 0;
			width: 100%;
		}

		&--red:after {
			background: linear-gradient(90deg, $dark-red 0%, $lighter-red 100%);
		}

		&--yellow:after {
			background: linear-gradient(90deg, $intensive-yellow 0%, $lighter-yellow 100%);
		}

		&--white {
			color: $light-grey;
		}

		&--white:after {
			background: linear-gradient(90deg, $plain-white 0%, $light-grey 100%);
		}

	}

	&--subheading {
		text-align: center;
		margin-top: 0;
		width: 80%;
    	margin: 0 auto;
		
		@include screen(tablet){
			margin-top:0.5em;
			width: 70%;
 		}
 		@include screen(desktop){
			margin-top:0.5em;
			width: 50%;
 		}

 		&-white {
			color: $light-grey;
		}
		&-extra-b-margin {
			margin-bottom: 1em;
		}
	}
}
