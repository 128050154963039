header {
	position:relative;
	height: 100%;
	@include screen(tablet) {
		height: 75vh;
	}
	@include screen(desktop) {
		height: 100vh;
	}
	&::after {
		content: "";
    	position: absolute;
    	left: 0;
    	right: 0;
    	bottom: 0;
    	top: 0;
    	background: $dark-layer;
    	z-index: 1;
	}
}

#header-background-video {
	display: none;
	@include screen(tablet) {
		display: block;
		position: absolute;
	    top: 50%;
	    left: 50%;
	    min-width: 100%;
	    width: auto;
	    height: 75vh;
	    z-index: -100;
	    -ms-transform: translateX(-50%) translateY(-50%);
	    -moz-transform: translateX(-50%) translateY(-50%);
	    -webkit-transform: translateX(-50%) translateY(-50%);
	    transform: translateX(-50%) translateY(-50%);
	    background: url(img/backgroundtop.jpg) no-repeat;
	    background-size: cover;
    }
    @include screen(desktop) {
		height: 100vh;
	}
}

#header-background-image {
	position: relative;
	display: block;
	
	@include screen(tablet) {
		display: none;
    }
}

.header-container {
	text-align: center;
	position: absolute;
	z-index: 10;
	height: 100px;
	top: 0;
	left: 0;
	right: 0;
	color: $plain-white;

	&-logo {
		margin-top: 10px;
		width: 65%;
		max-width: 270px;
		max-height: 80px;
		margin-left: auto;
		margin-right: auto;
		@include screen(smallmobileLandscape) {
			max-width: 230px;
			max-height: 60px;
		}
		@include screen(mobileLandscape) {
			max-width: 230px;
			max-height: 60px;
		}
		@include screen(tablet) {
			width: 12em;
    		margin-top: 24px;
			display: inline-block;
			float: left;
		}
	}

	&-name {
		float:left;
		text-indent: -9999999px;
	}
}

.key-messages {
	position:absolute;
	top: 35%;
	left: 0;
	right: 1em;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	z-index: 5;
	color: $plain-white;
	
	@include screen(tablet) {
	 	top: 22.5%;
	 	width: 80%;
	}
	
	@include screen(desktop) {
		top: 20%;
	 	width: 65%;
	}

	&-title {
		margin: 0;
		animation: key-messages-grow .5s ease backwards;
		color: $light-yellow;
	}

	&-content {
		width: 90%;
		margin-top: 7.5px;
	    margin-left: auto;
    	margin-right: auto;
		animation: key-messages-grow  .1s .5s ease backwards;

		@include screen(mobile) {
	 		margin-top: 15px;
	 		width: 80%;
		}

		@include screen(tablet) {
	 		margin-top: 30px;
	 		width: 100%;
		}

		@include screen(desktop) {
	 		margin-top: 35px;
	 		width: 100%;
		}
		
		&-text{
		display: inline;
		color: $light-yellow;
		}
    	
    	&-rotating-words {
    		color: $lime;
    		display:inline-block;
    		// width: 120px;
    		padding-right: 3px;
    		text-align: right;
    		@include screen(desktop) {
	 			display: block;
	 			text-align: center;
			}
    	}
	}

	&-subtitle {
		color: $intensive-yellow;
		animation: key-messages-grow  .3s .5s ease backwards;
		margin: .75em 0;
		@include screen(tablet) {
	 		margin: 1.25em 0 0.55em;
		}
	}
}

.button-inquiry {
	display:none;
	@include screen(tablet) {
	 	display: block;
	}
}

@keyframes key-messages-grow {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	10% {
		opacity: 1;
		transform: scale(0.8);
	}
	100%{
		transform: scale(1);
	}
}