.overlay {
	display: none;
	background-color: rgba(0,0,0,.9);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	color: #FEFFFE;
	overflow: hidden;
	z-index: 1000;

	&__main-image {
		display: block;
		width: 100%;
		padding: 1em 3em;		
		@include screen(mobileLandscape) {
			padding-top: 4.5em;
			padding-left: 1em;
			width: 50%;
		}
		@include screen(tablet) {
			padding: 1em;
			width: 90%;
		}
		@include screen(desktop) {
			padding: 2.5em;
			width: 80%;
		}
	}

	&__info {
		position: absolute;
		top: 20%;
		left: 1em;
		right: 1em;
		@extend %overlayContentBox;
		@include screen(smallmobileLandscape) {
			top: .5em;
			left: 45%;
			right: 2.5em;
		}
		@include screen(mobileLandscape) {
			top: 1em;
			left: 45%;
			right: 2.5em;
		}
		@include screen(tablet) {
			top: 7.5%;
			left: 32.5%;
			right: 1.5em;
    	}
    	@include screen(desktop) {
			top: 2.5%;
			left: 60%;
			right: 2.5em;
    	}
	}

	&__info-heading {
		background: rgba(0,0,0,.7);
		margin: 10px;
		padding: 7px;
	}

	&__info-subheading {
		text-align: center;
		margin: .75em 2em 0.25em 2em;
		@extend %subtitilesOnPopUp;
		@include screen(smallmobileLandscape) {
			margin: 0 2em 0.25em 2em;
		}
		@include screen(tablet) {
			margin: 1.1em 3em 0.25em 3em;
		}
	}

	&__info-subheading-mt {
		margin-top: 1.1em;
	}

	&__info-subheading-preview {
		background: $medium-red;
		line-height: .8;
		padding: 12.5px 0;
		width: 40%;
		margin: 0 auto;
		text-align: center;
		border-radius: 15px;
	}

	&__desciption-container {
		position: relative;
		height: 11.5em;
		@include screen(smallmobileLandscape) {
			height: 6.9em;
		}
		@include screen(mobileLandscape) {
			height: 7.85em;	
		}
		@include screen(tablet) {
			height: 16.25em;
		}
	}

	&__info-text {
		display: none;
		text-align: center;
		margin: 0 1em;
		padding: 5px 0;
		background: rgba(0,0,0,.7);
		@include screen(smallmobileLandscape) {
			padding: 1px 0;
		}
		@include screen(mobileLandscape) {
			padding: 2px 0;
		}

		&-row-container {
			overflow: hidden;
			padding: 0;
			margin: 0.25em 0;
			height: 30px;
			@include screen(smallmobileLandscape) {
				margin: 0.1em 0;
				height: 18px;
				padding: 0;
			}
			@include screen(mobileLandscape) {
				margin: 0.15em 0;
				height: 20px;
				padding: 0;
			}
			@include screen(tablet) {
				margin: 0.5em 0;
				height: 42px;
			}
		}
	}

	&__info-skills {
		color: $very-dark-grey;
		margin: 1em 0;
		@include screen(smallmobileLandscape) {
			margin: .25em 0;
		}
		@include screen(mobile) {
			margin: 1em;
		}	
		@include screen(tablet) {
			margin: 1em 3em .5em;
		}
	}

	&__live-preview {
		display: none;
		@extend %overlayContentBox;
		position: absolute;
		left: 1em;
		width: 16em;
		height: 9em;
		@include screen(tablet) {
			display: block;
			bottom: 3em;
    	}
    	@include screen(desktop) {
			bottom: 1em;
    	}
		h1 {
			margin: .25em;
			background: rgba(255,255,255,0.8);
		}
	}
	
	&__visit-site {
		position: absolute;
		height: 2em;
		left: 1em;
		right: 1em;
		bottom: .5em;
		@include screen(smallmobileLandscape) {
			right:auto;
		}
		@include screen(mobileLandscape) {
			right:auto;
		}
		@include screen(tablet) {
			height: 3em;
			left: 1em;
			right: auto;
			bottom: 12.25em;
			margin: .25em;
		}
		@include screen(desktop) {
			bottom: 10.5em;
		}
	}

}

.desc-content,
.desc-content-one,
.desc-content-two {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}


.reposiveLabel {
	position: absolute;
	left: -70px;
	top: 0;
	background: $medium-red;
	width: 300px;
	height: 40px;
	-ms-transform: rotate(-45deg); /* IE 9 */
    -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
    transform: rotate(-45deg);
    text-align: center;
    span {
    	display: inline-block;
    	margin-top: 0.625em;
    	margin-left: -5em;
    	text-transform: uppercase;
    }
}

.overlay-background {
	display: none;
	background-color: rgba(0,0,0,1);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 950;
}