* {
	min-height: 0;
	min-width: 0;
	box-sizing: border-box;
}

body {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

img,
canvas,
iframe {
	max-width: 100%;
}

ul {
	padding: 0;
}

/* ---- Float clearfix ---- */
.clearfix::after {
	content: " ";
	display: table;
	clear: both;
}