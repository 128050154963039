.inquiry-form {
	display: none;
	position: absolute;
	top: 100px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	border: 5px solid #f7f7f7;
    background: $light-yellow;
	z-index: 1000;

	&--fields {
		width: 90%;
		margin: 0 auto;
		input,
		textarea {
			display: block;
		}

		fieldset {
			border: none;
			padding: 3px 0 0;
			margin: 0;
		}


		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="url"],
		textarea {
			width:100%;
			margin: 0 auto;
			outline: 0;
			background-color: transparent;
			-webkit-transition: background 100ms ease-out 0.1s;
			-moz-transition: background 100ms ease-out 0.1s;
			-o-transition: background 100ms ease-out 0.1s;
			transition: background-color 100ms ease-out 0.1s;
			border: none;
			// border-radius: 3px;
			padding: 15.5px 20px;
		}

		textarea {
			padding: 12px 20px;
			resize:none;
		}

		input:hover,
		textarea:hover {
			// border-color: #daa300;
			border-color: $dark-red;
		}

		input[type="checkbox"] {
			display: none;
		}

		.checkbox::before {
			content: "";
			display: inline-block;
			position:relative;
			width: 15px;
			height: 15px;
			border: 2px solid #eff0f1;
			border-radius: 3px;
			background-color: #eff0f1;
			margin-right: 18px;
		}


		input[type=checkbox]:checked + .checkbox::before {
			content: "";
			background: url(img/icons/checkmark.svg);
			background-repeat: no-repeat;
			background-size: 15px 15px;
			transition: background 100ms ease 0.1s;
			border: 2px solid #a6a6a6;
			border-radius: 3px;
			box-shadow: inset 0 1px 0 rgba(255,255,255,.5), 0 1px 0 rgba(0, 0, 0, 0.07);
			box-shadow:0 0 3px 1px rgba(228,121,17,.5);
			width: 15px;
			height: 15px;
			margin-right: 18px;
		}

		label.checkbox {
			margin-top:11px;
			margin-bottom: 0;
			vertical-align: top;
		}

		input[type="text"]:focus,
		input[type="email"]:focus,
		input[type="tel"]:focus,
		textarea:focus
		 {
			background-color: #fff;
			-webkit-transition: background 100ms ease-in 0.1s;
			-moz-transition: background 100ms ease-in 0.1s;
			-o-transition: background 100ms ease-in 0.1s;
			transition: background 100ms ease-in 0.1s;
			border: 1px solid #a6a6a6;
			border-radius: 3px;
			box-shadow: 0 1px 0 rgba(255,255,255,.5),0 1px 0 rgba(0,0,0,.07) inset;
			box-shadow: 0 0 3px 1px rgba(173,33,49,.5);
			// box-shadow: 0 0 3px 1px rgba(228,121,17,.5);
		}
		&-field {
			position: relative;
			width: 100%;
			padding-bottom: 1em;

			hr {
				display: block;
			    position: absolute;
			    bottom: 0;
			    left: 0;
			    width: 100%;
			    height: 2px;
			    border: 0;
			    border-radius: 4px;
			    margin: 0;
			    background: #ebebeb;

			    &:after {
			    	-webkit-transition: all 150ms ease-out;
				    transition: all 150ms ease-out;
				    -webkit-transform: scaleX(0) translateZ(0);
				    transform: scaleX(0) translateZ(0);
				    -webkit-transform-origin: left top;
				    transform-origin: left top;
				    will-change: transform;
				    content: "";
				    display: block;
				    position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    height: 100%;
				    border-radius: 4px;
				    background: #cd908b;
			    }
			}
		}
	}
}

.inquiry-form--fields-field input:focus ~ hr:after {
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
}

